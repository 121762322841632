<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="25" size="35" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems"
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
					<template v-slot:top>
						<div class="d-flex flex-wrap pt-3 pb-1 pl-2" style="gap:16px" ref="tabletopbar">
							<v-btn-toggle mandatory dense v-model="filter" @change="reloadData">
								<v-btn>待收费</v-btn>
								<v-btn>已收费</v-btn>
								<v-btn>全部</v-btn>
							</v-btn-toggle>
							<v-text-field v-model.trim="pname" label="客户姓名" clearable dense hide-details style="flex: 0 1 auto;max-width:150px;"></v-text-field>
							<v-btn color="primary" @click.stop="reloadData">查询</v-btn>
						</div>
						<v-divider/>
					</template>
					<template v-slot:item.insurance="{ item }">
						<span :class="item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
					</template>
					<template v-slot:item.apntOprtDate="{ item }">
						{{formatTime(item.apntOprtDate)}}
					</template>
					<template v-slot:item.progress.operative="{ item }">
						{{formatTime(item.progress.operative)}}
					</template>
					<template v-slot:item.progress.chargeMisc="{ item }">
						{{formatTime(item.progress.chargeMisc)}}
					</template>
					<template v-slot:item.opts="{ item }">
						{{getOptsStr(item)}}
					</template>
				</v-data-table>
			</pane>
			<pane min-size="20" size="65" v-if="selected.length > 0">
				<div style="max-height: 100%" class="d-flex overflow-y-auto">
					<div class="pt-2 pl-4">
						<v-text-field label="爽约费" v-model.number="chargeMisc.ba" type="number" :min="0" hide-spin-buttons/>
						<v-text-field label="加急费" v-model.number="chargeMisc.urgent" type="number" :min="0" hide-spin-buttons/>
						<v-text-field label="其他" v-model.number="chargeMisc.other" type="number" :min="0" hide-spin-buttons/>
						<v-text-field label="合计" :value="total" readonly/>
						<v-select label="收费方式" :items="['前台付款','统一结算']" v-model="chargeMiscWay"/>
						<div class="pt-6 pb-4">
							<v-btn class="primary" style="width:120px" :loading="loading" :disabled="loading||(!canEdit && !!selected[0].chargeMisc)" @click.stop="save">保存收费项</v-btn>
							<v-btn class="primary ml-2" style="width:120px" :disabled="loading||!canDo||!selected[0].chargeMisc" @click.stop="chargeComplete">执行收费</v-btn>
						</div>
					</div>
				</div>
			</pane>
		</splitpanes>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, formatDate} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'预约手术时间', value:'apntOprtDate', width:180},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'收费时间', value:'progress.chargeMisc', width:180},
                    {text:'收费人', value:'operator.chargeMisc', width:100},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				filter: 0,
				options: {sortBy:['apntOprtDate'], sortDesc:[true]},
				totalItems: 0,
				tableheight: undefined,
				pname: '',
				canEdit: false,
				canDo: false,
				chargeMisc: {},
				chargeMiscWay: '前台付款',
            }
        },
		created() {
			this.formatTime = formatTime;
		},
        mounted() {
			this.authed = this.$hasPrivilege(['其他收费','商务','健康顾问']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege(['商务']);
			this.canDo = this.$hasPrivilege(['其他收费','商务']);
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			async reloadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.$hasPrivilege(['其他收费','商务'])) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					this.consultantId = u.consultantId;
					filter.push({consultantId: u.consultantId});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				const filters = [
					{'chargeMisc':_.exists(true), 'progress.chargeMisc':_.exists(false)},
					{'progress.chargeMisc':_.exists(true)},
					{'payment.actual':_.exists(true)},
				];
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async save() {
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'saveChargeMisc',
						data: {
							id,
							chargeMisc: this.chargeMisc,
							chargeMiscWay: this.chargeMiscWay,
						}
					}});
					this.$dialog.message.success('保存完成');
					await this.fetchData();
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
			async chargeComplete() {
					
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'doChargeMisc',
						data: {
							id,
						}
					}});
					this.$dialog.message.success('完成');
					await this.fetchData();
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('失败');
				}
				this.loading = false;
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) {
					return;
				}
				this.chargeMisc = newitem[0].chargeMisc || this.$options.data().chargeMisc;
				this.chargeMiscWay = newitem[0].chargeMiscWay || this.$options.data().chargeMiscWay;
			}
		},
		computed: {
			total() {
				if (this.selected.length === 0) return null;
				const values = Object.values(this.chargeMisc);
				return values.reduce((a, c) => a + c, 0);
			}
		},
        components: { Splitpanes, Pane }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
</style>